@mixin flex($direction: row) {
	display: flex;
	flex-direction: $direction;
}
@for $i from 1 through 5 {
	.el-line-#{$i} {
		@if $i == '1' {
			overflow: hidden;
			white-space: nowrap;
			display: -webkit-box;
			text-overflow: ellipsis;
		} @else {
			display: -webkit-box!important;
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: break-all;
			-webkit-line-clamp: $i;
			-webkit-box-orient: vertical!important;
		}
	}
}
// flex布局
.el-flex,
.el-flex-row,
.el-flex-x {
	@include flex;
}

.el-flex-y,
.el-flex-column {
	@include flex('column');
}

.el-flex-x-center {
	@include flex;
	justify-content: center;
}

.el-flex-xy-center {
	@include flex;
	justify-content: center;
	align-items: center;
}

.el-flex-y-center {
	@include flex;
	align-items: center;
}

.el-flex-x-left {
	@include flex;
}

.el-flex-x-reverse,
.el-flex-row-reverse {
	flex-direction: row-reverse;
}

.el-flex-y-reverse,
.el-flex-column-reverse {
	flex-direction: column-reverse;
}

/* #ifndef APP-NVUE */
// 此处为vue版本的简写，因为nvue不支持同时作用于两个类名的样式写法
// nvue下只能写成class="u-flex-x u-flex-x-reverse的形式"
.el-flex.el-flex-reverse,
.el-flex-row.el-flex-reverse,
.el-flex-x.el-flex-reverse {
	flex-direction: row-reverse;
}

.el-flex-column.el-flex-reverse,
.el-flex-y.el-flex-reverse {
	flex-direction: column-reverse;
}

// 自动伸缩
.el-flex-fill {
	flex: 1 1 auto
}

// 换行
.el-flex-wrap {
	flex-wrap: wrap;
}

// 反向换行
.el-flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

// 主轴起点对齐
.el-flex-start {
	justify-content: flex-start
}

// 主轴中间对齐
.el-flex-center {
	justify-content: center
}

// 主轴终点对齐
.el-flex-end {
	justify-content: flex-end
}

// 主轴等比间距
.el-flex-between {
	justify-content: space-between
}

// 主轴均分间距
.el-flex-around {
	justify-content: space-around
}

// 交叉轴起点对齐
.el-flex-items-start {
	align-items: flex-start
}

// 交叉轴中间对齐
.el-flex-items-center {
	align-items: center
}

// 交叉轴终点对齐
.el-flex-items-end {
	align-items: flex-end
}

// 交叉轴第一行文字基线对齐
.el-flex-items-baseline {
	align-items: baseline
}

// 交叉轴方向拉伸对齐
.el-flex-items-stretch {
	align-items: stretch
}


// 以下属于项目(子元素)的类

// 子元素交叉轴起点对齐
.el-flex-self-start {
	align-self: flex-start
}

// 子元素交叉轴居中对齐
.el-flex-self-center {
	align-self: center
}

// 子元素交叉轴终点对齐
.el-flex-self-end {
	align-self: flex-end
}

// 子元素交叉轴第一行文字基线对齐
.el-flex-self-baseline {
	align-self: baseline
}

// 子元素交叉轴方向拉伸对齐
.el-flex-self-stretch {
	align-self: stretch
}

// 多轴交叉时的对齐方式

// 起点对齐
.el-flex-content-start {
	align-content: flex-start
}

// 居中对齐
.el-flex-content-center {
	align-content: center
}

// 终点对齐
.el-flex-content-end {
	align-content: flex-end
}

// 两端对齐
.el-flex-content-between {
	align-content: space-between
}

// 均分间距
.el-flex-content-around {
	align-content: space-around
}

// 全部居中对齐
.el-flex-middle {
	justify-content: center;
	align-items: center;
	align-self: center;
	align-content: center
}

// 是否可以放大
.el-flex-grow {
	flex-grow: 1
}

// 是否可以缩小
.el-flex-shrink {
	flex-shrink: 1
}

